import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Subitem } from '../item/item.component';
import { isMobile } from '../../../utils/mobile';

@Component({
  selector: 'ui-nav',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavSidebarComponent implements OnInit {

  @Input()
  enabledFeatures$!: Observable<any>;

  subitems: Subitem[] = [
    // {
    //   label: 'Datacenter',
    //   icon: 'circle-stack',
    //   isActive: false,
    //   route: 'services/datacenter'
    // },
    // {
    //   label: 'Infrastructure',
    //   icon: 'globe-alt',
    //   isActive: false,
    //   route: 'services/infrastructure'
    // },
    {
      label: 'Cloud Services',
      icon: 'cloud',
      isActive: false,
      route: 'services/cloud-services'
    }
    // {
    //   label: 'S3 Storage',
    //   icon: 'circle-stack',
    //   isActive: false,
    //   route: 'services/storage'
    // }
    // {
    //   label: 'Managed Services',
    //   icon: 'adjustments-vertical',
    //   isActive: false,
    //   route: 'services/datacenter'
    // }
  ];

  collapsed = false;

  @Input()
  isAuthenticated = false;

  @Input()
  isAdmin = false;

  @Input()
  isDcamServiceOwner = false;

  @Input()
  profile!: {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
  } | null;

  @Input()
  companyName?: string;

  @Input()
  companies$!: Observable<any[]>;

  @Output()
  changeCompany = new EventEmitter<string>();

  @Input()
  activeItem?: string;

  @Output()
  logout: EventEmitter<string> = new EventEmitter();

  @Output()
  routeChange: EventEmitter<string> = new EventEmitter();

  @Output()
  sidebarCollapse: EventEmitter<boolean> = new EventEmitter();

  ngOnInit(): void {
    this.collapsed = localStorage.getItem('isSidebarCollapsed') === 'true' ? true : false;
    if (isMobile()) {
      this.collapsed = true;
    }
  }

  onRouteChange(route: string) {
    this.routeChange.emit(route);

    if (isMobile()) {
      this.collapsed = true;
    }
  }

  toggleSidebar() {
    this.collapsed = !this.collapsed;
    this.sidebarCollapse.emit(this.collapsed);
    localStorage.setItem('isSidebarCollapsed', this.collapsed.toString());
  }
}
