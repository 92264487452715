import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, NonNullableFormBuilder } from "@angular/forms";
import { Icon } from "@n/nui";

@Component({
  selector: 'tx-portal-simplified-search',
  templateUrl: './simplified-search.component.html',
})
export class SimplifiedSearch {
  isMac!: boolean;

  constructor(private fb: NonNullableFormBuilder) {
    this.isMac = !(navigator.userAgent.indexOf('Max OS X') != -1);
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if(this.isMac) {
      if(event.key === "k" && event.metaKey) {
        this.input.nativeElement.focus();
      }
    } else {
      if(event.key === "k" && event.ctrlKey) {
        this.input.nativeElement.focus();
      }
    }
  }

  searchForm = this.fb.group({ input: '' });

  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() onReset: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('input') input!: ElementRef;

  search() {
    this.onSearch.emit(this.searchForm.controls.input.value);
  }

  reset() {
    this.searchForm.controls.input.reset();
    this.onReset.emit();
  }

}