<div class="min-h-screen flex justify-center items-center" *ngIf="!(this.authService.isAuthenticated$ | async)">
  <ui-icon [icon]="'sun'" class="animate-spin w-6 h-6 block"></ui-icon>
</div>
<div class="min-h-screen flex" *ngIf="this.authService.isAuthenticated$ | async">
  <nav
    class="h-screen bg-gray-800 flex flex-col transition-all ease-in-out duration-200 fixed top-0 z-50"
    [ngClass]="!collapsed ? 'px-8 xl:w-96 w-full' : 'collapsed px-4 w-[72px]'"
  >
    <div class="py-12 flex flex-col items-center justify-center">
      <img *ngIf="!collapsed" class="h-10" src="/assets/logo-klee.svg" />
      <span class="font-semibold whitespace-nowrap" *ngIf="!collapsed">KDC Admin Center</span>
      <img *ngIf="collapsed" class="h-10" src="/assets/logo-klee-short.svg" />
      <span class="font-semibold" *ngIf="collapsed">AC</span>
    </div>
    <div class="py-4 flex flex-col gap-2">
      <span *ngIf="!collapsed" class="font-medium text-gray-500 block">Kundencenter</span>
      <span *ngIf="collapsed" class="bg-gray-500 w-10 h-px mt-3 mb-[11px]"></span>
      <tx-portal-nav-item
        *ngIf="authService.identityClaims['groups'].includes('ASG_AdminApp_KDC_Modul_Dashboard_R')"
        [type]="'link'"
        [label]="'Dashboard'"
        [link]="'kundencenter/dashboard'"
        [icon]="'chart-pie'"
        [collapsed]="collapsed"
      ></tx-portal-nav-item>
      <tx-portal-nav-item
        *ngIf="authService.identityClaims['groups'].includes('ASG_AdminApp_KDC_Modul_Company_R')"
        [type]="'link'"
        [label]="'Organisationen'"
        [link]="'kundencenter/organisation'"
        [icon]="'briefcase'"
        [collapsed]="collapsed"
      ></tx-portal-nav-item>
      <tx-portal-nav-item
        *ngIf="authService.identityClaims['groups'].includes('ASG_AdminApp_KDC_Modul_Accounts_R')"
        [type]="'link'"
        [label]="'People'"
        [link]="'kundencenter/logins'"
        [icon]="'user-group'"
        [collapsed]="collapsed"
      ></tx-portal-nav-item>
      <tx-portal-nav-item
        *ngIf="authService.identityClaims['groups'].includes('ASG_AdminApp_KDC_Modul_Accounts_R')"
        [type]="'link'"
        [label]="'Accounts'"
        [link]="'kundencenter/account'"
        [icon]="'arrow-right-on-rectangle'"
        [collapsed]="collapsed"
      ></tx-portal-nav-item>
      <tx-portal-nav-item
        *ngIf="authService.identityClaims['groups'].includes('ASG_AdminApp_KDC_Modul_Services_R')"
        [type]="'link'"
        [label]="'Services'"
        [link]="'kundencenter/services'"
        [icon]="'squares-2x2'"
        [collapsed]="collapsed"
      ></tx-portal-nav-item>
      <tx-portal-nav-item
        *ngIf="authService.identityClaims['groups'].includes('ASG_AdminApp_KDC_Modul_Invoices_R')"
        [type]="'link'"
        [label]="'Rechnungen'"
        [link]="'kundencenter/invoices'"
        [icon]="'currency-euro'"
        [collapsed]="collapsed"
      ></tx-portal-nav-item>
      <tx-portal-nav-item
        [type]="'link'"
        [label]="'Datacenter Access'"
        [link]="'kundencenter/dcam'"
        [icon]="'key'"
        [collapsed]="collapsed"
      ></tx-portal-nav-item>
    </div>
    <!-- rest -->
    <div class="py-4 flex flex-col gap-2 mt-auto" *ngIf="authService.identityClaims as _identityClaims">
      <tx-portal-nav-item
        [type]="'button'"
        [label]="'Menu einklappen'"
        [icon]="!collapsed ? 'arrow-left-circle' : 'arrow-right-circle'"
        [collapsed]="collapsed"
        (cclick)="collapsed = !collapsed"
      ></tx-portal-nav-item>
      <tx-portal-nav-item
        [type]="'link'"
        [label]="'Ich brauche eine Pause'"
        [link]="'easteregg'"
        [icon]="'bell'"
        [collapsed]="collapsed"
      ></tx-portal-nav-item>
      <div class="relative">
        <tx-portal-nav-item
          [type]="'display'"
          [label]="_identityClaims['email']"
          [icon]="'user-circle'"
          [collapsed]="collapsed"
        ></tx-portal-nav-item>
      </div>
      <tx-portal-nav-item
        [type]="'logout'"
        [label]="'Abmelden'"
        [icon]="'arrow-left-on-rectangle'"
        [collapsed]="collapsed"
        (cclick)="logout()"
      ></tx-portal-nav-item>
    </div>
  </nav>
  <main
    class="ransition-all ease-in-out duration-200"
    [ngClass]="
      !collapsed
        ? 'w-[calc(100%-72px)] ml-[72px] xl:w-[calc(100%-24rem)] xl:ml-[24rem]'
        : 'ml-[72px] w-[calc(100%-72px)]'
    "
  >
    <router-outlet></router-outlet>
  </main>
</div>
