<div class="md:hidden fixed top-4 right-4 sm:top-8 sm:right-8 z-50 h-[54px] flex items-center justify-center">
  <ui-button
    [size]="'sm'"
    [isOnlyIcon]="true"
    [icon]="collapsed ? 'bars-2' : 'x-mark'"
    (cclick)="toggleSidebar()"
    [title]="'Navigation ' + (collapsed ? 'erweitern' : 'verkleinern')"
  ></ui-button>
</div>

<nav
  class="fixed flex flex-col min-h-screen bg-white flex-nowrap h-full overflow-y-auto overflow-x-hidden md:overflow-auto z-40"
  [ngClass]="collapsed ? 'w-collapsed pt-8 p-0 md:p-4' : 'w-full p-4 sm:p-8 md:w-96'"
>
  <object
    class="flex-shrink-0 h-12 w-full"
    [ngClass]="collapsed ? 'mb-12' : 'mb-16'"
    type="image/svg+xml"
    data="/assets/logos/tx-logo-app.svg"
  ></object>
  <div class="hidden md:block mb-12" [ngClass]="collapsed ? 'relative w-full' : 'absolute right-8'">
    <div class="flex items-center justify-center h-12">
      <ui-button
        [size]="'sm'"
        [isOnlyIcon]="true"
        [icon]="collapsed ? 'chevron-right' : 'chevron-left'"
        [color]="'ghost'"
        (cclick)="toggleSidebar()"
        [title]="'Navigation ' + (collapsed ? 'erweitern' : 'verkleinern')"
      ></ui-button>
    </div>
  </div>

  <ui-nav-profile
    *ngIf="profile"
    [collapsed]="collapsed"
    [isActive]="activeItem === '/profile'"
    [firstname]="profile.firstName"
    [lastname]="profile.lastName"
    [companyName]="companyName + ''"
    [companies$]="companies$"
    [email]="profile.email"
    (changeCompany)="changeCompany.emit($event)"
    (cclick)="onRouteChange('profile')"
  ></ui-nav-profile>
  <div class="flex flex-col gap-2 mt-12">
    <ui-nav-item
      [collapsed]="collapsed"
      [label]="'Dashboard'"
      [icon]="'squares-2x2'"
      [isActive]="activeItem === '/dashboard'"
      (routeHandler)="onRouteChange($event)"
      [route]="'dashboard'"
    ></ui-nav-item>
    <!-- *ngIf="(enabledFeatures$ | async)?.['isProductViewEnabled']" -->
    <ui-nav-item
      [collapsed]="collapsed"
      [label]="'Produkte'"
      [icon]="'cube'"
      [isActive]="activeItem === '/products'"
      (routeHandler)="onRouteChange($event)"
      [route]="'products'"
    ></ui-nav-item>
    <ui-nav-item
      [collapsed]="collapsed"
      [label]="'Services'"
      [icon]="'queue-list'"
      [isActive]="activeItem === '/services'"
      [subitems]="subitems"
      (routeHandler)="onRouteChange($event)"
      [route]="'services'"
    ></ui-nav-item>
    <ui-nav-item
      *ngIf="isAdmin && (enabledFeatures$ | async)?.['isInvoiceEnabled']"
      [collapsed]="collapsed"
      [label]="'Rechnungen'"
      [icon]="'currency-euro'"
      [isActive]="activeItem === '/invoices'"
      (routeHandler)="onRouteChange($event)"
      [route]="'invoices'"
    ></ui-nav-item>
    <ui-nav-item
      *ngIf="(isAdmin || isDcamServiceOwner) && (enabledFeatures$ | async)?.['isDcamEnabled']"
      [collapsed]="collapsed"
      [label]="'Datacenter Access'"
      [icon]="'key'"
      [isActive]="activeItem === '/dcam'"
      (routeHandler)="onRouteChange($event)"
      [route]="'dcam'"
    ></ui-nav-item>
    <ui-nav-item
      [collapsed]="collapsed"
      [label]="'Ansprechpartner & Support'"
      [icon]="'ticket'"
      [isActive]="activeItem === '/support'"
      (routeHandler)="onRouteChange($event)"
      [route]="'support'"
    ></ui-nav-item>
  </div>
  <div class="relative flex items-center justify-start mt-8 mb-2" *ngIf="isAdmin">
    <hr class="border-gray-100 w-full absolute" />
    <span class="text-xs font-medium uppercase text-gray-200 bg-white pr-2 block relative" *ngIf="!collapsed"
      >Adminbereich</span
    >
  </div>
  <div class="flex flex-col gap-2 mb-2">
    <ui-nav-item
      *ngIf="isAdmin"
      [collapsed]="collapsed"
      [label]="'Accountverwaltung'"
      [icon]="'user-group'"
      [isActive]="activeItem === '/account-management'"
      (routeHandler)="onRouteChange($event)"
      [route]="'account-management'"
    ></ui-nav-item>
    <ui-nav-item
      *ngIf="isAdmin"
      [collapsed]="collapsed"
      [label]="'Organisation'"
      [icon]="'briefcase'"
      [isActive]="activeItem === '/organization'"
      (routeHandler)="onRouteChange($event)"
      [route]="'organization'"
    ></ui-nav-item>
    <ui-nav-item
      *ngIf="isAdmin"
      [collapsed]="collapsed"
      [label]="'Grundeinstellungen'"
      [icon]="'cog-8-tooth'"
      [isActive]="activeItem === '/settings'"
      (routeHandler)="onRouteChange($event)"
      [route]="'settings'"
    ></ui-nav-item>
  </div>
  <div class="flex flex-col mt-auto">
    <ui-nav-item
      id="abmelden"
      [collapsed]="collapsed"
      [label]="'Abmelden'"
      [icon]="'arrow-left-on-rectangle'"
      [isSecondary]="true"
      (routeHandler)="logout.emit($event)"
    ></ui-nav-item>
  </div>
</nav>
