import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-modal',
  templateUrl: './modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnChanges {
  @Input() title = '';

  @Input()
  isModalOpen!: boolean;

  @Input()
  submitLabel = 'Speichern';

  @Input()
  isSubmitValid: boolean = true;

  @Input()
  isSubmitDanger = false;

  @Input()
  closeLabel = 'Schließen';

  @Input()
  showButtons = true;

  @Input() isCloseButtonVisible = true;

  animate = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onClose = new EventEmitter<Event>();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onSubmit = new EventEmitter<Event>();

  constructor(private cdr: ChangeDetectorRef) {}

  close() {
    this.animate = false;
    this.cdr.detectChanges();

    setTimeout(() => {
      this.onClose.emit();
    }, 300);
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.animate = true;
      this.cdr.detectChanges();
    }, 0);
  }
}
