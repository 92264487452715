import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Icon } from '@n/nui';

@Component({
  selector: 'ui-forms-textarea, [txTextarea]',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  // Inputs
  @Input() parentForm!: FormGroup;
  @Input() controlName!: string;
  @Input() label = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() icon: Icon | null = null;

  // Outputs
  @Output() ffocus = new EventEmitter<FocusEvent>();
  @Output() bblur = new EventEmitter<FocusEvent>();
  @Output() kkeyup = new EventEmitter<KeyboardEvent>();
  @Output() cchange = new EventEmitter<any>();

  isRequired: boolean = false;
  ngOnInit() {
    this.isRequired = this.parentForm.controls[this.controlName].hasValidator(Validators.required);
  }
}
