<div>
  <label class="block text-sm font-medium text-gray-700 mb-1" *ngIf="label">{{ label }}</label>
  <div class="relative">
    <button
      (click)="isOpen = !isOpen"
      type="button"
      class="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md cursor-pointer focus:outline-none focus:ring focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
      aria-haspopup="listbox"
      aria-expanded="false"
    >
      <span class="inline-flex w-full truncate" *ngIf="selectedValue">
        <span class="truncate"> {{ selectedValue.label }} </span>
        <span class="ml-1 text-gray-500 truncate" *ngIf="!optionsAsColumn"> {{ selectedValue.description }} </span>
      </span>
      <span class="absolute inset-y-0 right-0 flex items-center h-full pr-2 pointer-events-none">
        <ui-icon class="w-5 h-4 text-gray-400" [icon]="'chevron-up-down'"></ui-icon>
      </span>
    </button>

    <ul
      *ngIf="isOpen"
      class="absolute z-10 w-full p-1 mt-1 overflow-auto text-base bg-white shadow-lg rounded-xl max-h-60 focus:outline-none sm:text-sm"
      role="listbox"
    >
      <li
        (click)="select(option)"
        *ngFor="let option of options; index as i"
        class="relative flex items-center gap-2 px-3 py-2 transition duration-200 ease-in-out rounded-lg cursor-pointer select-none hover:bg-gray-100 hover:text-blue-500"
        [ngClass]="[highlightIndex === i ? itemStyles : '']"
        role="option"
      >
        <span
          *ngIf="selectedValue?.value === option.value"
          class="block w-2 h-2 bg-blue-500 rounded-full shrink-0"
        ></span>
        <div class="flex" [ngClass]="optionsAsColumn ? 'flex-col' : ''">
          <span class="font-normal truncate"> {{ option.label }} </span>
          <span class="text-gray-500 truncate" [ngClass]="optionsAsColumn ? '' : 'ml-1'">
            {{ option.description }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</div>
