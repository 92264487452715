<!-- This example requires Tailwind CSS v2.0+ -->
<div
  class="fixed inset-0 z-50 overflow-x-hidden overflow-y-scroll"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
  *ngIf="isModalOpen"
>
  <div class="flex items-center justify-center p-4 sm:p-12 text-center min-h-screen">
    <!-- darken bg -->
    <div
      *ngIf="isCloseButtonVisible"
      class="fixed inset-0 transition-opacity duration-300 ease-out bg-black bg-opacity-50 opacity-0"
      [ngClass]="{ 'opacity-100': animate }"
      aria-hidden="true"
      (click)="close()"
    ></div>
    <div
      *ngIf="!isCloseButtonVisible"
      class="fixed inset-0 transition-opacity duration-300 ease-out bg-black bg-opacity-50 opacity-0"
      [ngClass]="{ 'opacity-100': animate }"
      aria-hidden="true"
    ></div>

    <div
      class="inline-flex flex-col items-center justify-start text-left align-bottom transition-all duration-300 ease-out transform bg-white opacity-0 sm:rounded-3xl rounded-2xl sm:align-middle sm:max-w-4xl w-full translate-y-0 sm:scale-95"
      [ngClass]="{ 'opacity-100 translate-y-0 sm:scale-100': animate }"
    >
      <div class="relative w-full p-4 sm:p-8">
        <ui-button
          *ngIf="isCloseButtonVisible && !title"
          class="absolute top-4 right-4 sm:top-8 sm:right-8"
          [icon]="'x-mark'"
          [size]="'xs'"
          [color]="'gray'"
          (cclick)="close()"
          [isOnlyIcon]="true"
        ></ui-button>
        <!-- title -->
        <div class="flex justify-between gap-4 flex-nowrap mb-4 sm:mb-8" *ngIf="title">
          <ui-headline [heading]="title" size="lg" class="block"></ui-headline>
          <ui-button
            *ngIf="isCloseButtonVisible"
            [icon]="'x-mark'"
            [size]="'xs'"
            [color]="'gray'"
            (cclick)="close()"
            [isOnlyIcon]="true"
          ></ui-button>
        </div>
        <ng-content></ng-content>
      </div>
      <div class="flex items-center justify-end w-full p-8 pt-0 gap-4" *ngIf="showButtons">
        <ui-button
          *ngIf="closeLabel"
          [label]="closeLabel"
          [size]="'sm'"
          [color]="'ghost'"
          (click)="onClose.emit($event)"
        >
        </ui-button>
        <ui-button
          *ngIf="submitLabel"
          [isDisabled]="!isSubmitValid"
          [label]="submitLabel"
          [color]="!isSubmitDanger ? 'goblin' : 'red'"
          [size]="'sm'"
          (click)="onSubmit.emit($event)"
        >
        </ui-button>
      </div>
    </div>
  </div>
</div>
