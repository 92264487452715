<button
  type="button"
  class="nav-item"
  [ngClass]="[isActive ? 'active' : '']"
  (click)="routeHandler.emit(route)"
  [title]="label"
>
  <div class="flex items-center gap-4">
    <ui-icon [icon]="icon" class="w-5 h-5" [ngClass]="collapsed ? 'm-0.5' : ''"></ui-icon>
    <span *ngIf="!collapsed">{{ label }}</span>
  </div>
  <!-- sub items -->
  <div
    class="flex flex-col gap-2 mt-4 flex-nowrap"
    *ngIf="subitems.length > 0"
    [ngClass]="collapsed ? 'items-center w-full ml-0 -mb-2.5' : 'items-start w-[calc(100%-23px)] ml-[23px]'"
  >
    <button
      *ngFor="let subitem of subitems"
      class="nav-sub-item"
      [ngClass]="!collapsed ? 'w-full' : 'w-auto'"
      (click)="routeHandler.emit(subitem.route)"
    >
      <ui-icon [icon]="subitem.icon" class="w-4 h-4" [ngClass]="!collapsed ? 'mr-2' : 'm-0.5'"></ui-icon>
      <span *ngIf="!collapsed">{{ subitem.label }}</span>
    </button>
  </div>
</button>
