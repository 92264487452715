<div class="rounded-xl w-full p-8 border border-gray-700 flex flex-col h-full">
  <div class="flex gap-2 items-start">
    <ui-icon [icon]="icon" class="shrink-0 w-5 h-5 mt-1"></ui-icon>
    <span class="font-semibold text-xl">{{ label }}</span>
  </div>
  <span *ngIf="sub" class="font-sm text-gray-500 mt-1">{{ sub}}</span>
  <div class="font-semibold text-3xl pt-8 mt-auto">
    {{ count | number }}
  </div>
</div>
