import { NgModule, LOCALE_ID, APP_INITIALIZER, Pipe } from '@angular/core';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);

import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NuiModule } from '@n/nui';
import { SharedModule } from './shared/shared.module';
import { CommonModule, registerLocaleData } from '@angular/common';
import { TxAdminApiModule } from '@tx-admin-api';
import { APOLLO_FLAGS, APOLLO_OPTIONS } from 'apollo-angular';
import { createApollo } from '@tx/api';
import { HttpLink } from 'apollo-angular/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NotificationService } from '@tx/ui';
import { APP_CONFIG_TOKEN, environment } from '../environments/environment';

import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';

import { ToastrModule } from 'ngx-toastr';
import { authAppInitializerFactory } from './auth/auth-app-initializer.factory';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { PipesModule } from './pipes/pipes.module';
import { TokenInterceptor } from './token.interceptor';
// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatMenuModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    HttpClientModule,
    SharedModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot(
      [
        { path: '', redirectTo: 'kundencenter', pathMatch: 'prefix' },
        {
          path: 'kundencenter',
          loadChildren: () => import('./kundencenter/kundencenter.module').then((m) => m.KundencenterModule)
        },
        {
          path: 'easteregg',
          loadChildren: () => import('./easteregg/easteregg.module').then((m) => m.EastereggModule)
        },
        {
          path: 'calcify',
          loadChildren: () => import('./calcify/calcify.module').then((m) => m.CalcifyModule),
          canActivate: [AuthGuard],
          data: { roles: ['ASG_AdminApp_Calcify_R'] }
        }
      ],
      { initialNavigation: 'enabledBlocking' }
    ),
    MatSlideToggleModule,
    NuiModule,
    TxAdminApiModule,
    OAuthModule.forRoot(),
    ToastrModule.forRoot(),
    AuthModule,
    PipesModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
    { provide: APP_CONFIG_TOKEN, useValue: environment },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: false,
        useMutationLoading: true // enable it here
      }
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, OAuthService, NotificationService, APP_CONFIG_TOKEN]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    NotificationService,
    PipesModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    console.log('APP MOD');
  }
}
