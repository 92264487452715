<div class="flex items-center justify-center mx-auto max-w-4xl">
  <form
    (submit)="$event.preventDefault(); search()"
    [formGroup]="searchForm"
    class="rounded-2xl border border-gray-700 p-2 flex items-center justify-between w-full relative focus-within:border-blue-500"
  >
    <div class="flex items-center justify-center border-2 border-current flex-nowrap shrink-0 rounded-lg text-sm px-2 py-[3px] text-gray-500 font-semibold">
      {{ isMac ? '⌘' : 'Strg' }}K
    </div>
    <input
      #input
      class="w-full border-0 bg-transparent focus:outline-none focus:ring-0 placeholder:text-gray-600 p-0 px-2 text-sm"
      placeholder="Tabelle durchsuchen"
      [formControlName]="'input'"
    />
    <ui-button
      [ngClass]="'pr-2'"
      *ngIf="searchForm.controls.input.value"
      [size]="'xs'"
      [label]="'Zurücksetzen'"
      [color]="'red'"
      [icon]="'backspace'"
      (cclick)="reset()"
    ></ui-button>
    <ui-button
      submit
      [size]="'xs'"
      [label]="'Suchen'"
      [color]="'blue'"
      [icon]="'magnifying-glass'"
      (cclick)="search()"
    ></ui-button>
  </form>
</div>